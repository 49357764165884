.CaptchaContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: "center";
    height: 100%;
    background-color: "#FAFAFA";
}
.items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    height: 100vh;
    padding: 0 24px;
}
.ClientName{
	font-family: "Roboto", sans-serif;
color: #313131;
	font-weight: 500;
	font-size: 38px;
	line-height: 60px;
}
.verify{
	font-family: "Roboto", sans-serif;
	color: #313131;

	font-weight: 500;
	font-size: 28px;
	line-height: 36px;
}
.info{
	color: #313131;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	line-height: 46px;
	font-size: 20px;

	margin-top: 45px
}
.image{
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin-right: 10px ;
	object-fit: contain;
}
  