@keyframes inAnimation {
	0% {
		opacity: 0;
		max-height: 0px;
	}
	100% {
		opacity: 1;
		max-height: 600px;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
		max-height: 600px;
	}
	100% {
		opacity: 0;
		max-height: 0px;
	}
}
