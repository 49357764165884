.question-search input {
	display: block;
	width: 100%;
	padding: 0.5em 1em;
	background: white url("../../../assets/Loop.svg") no-repeat right 0.5em center;
	background-size: 1em;
	font-size: 1em;
	border-radius: 5px;
	color: #000;
	border: 1px solid #c9c9d6;
}

.question-search input:focus-visible {
	outline: none;
	border: 1.25px solid #00a6d9;
	box-shadow: #00a6d9 1px 2px 5px -1px;
}

.question-search input::placeholder {
	color: #c4c4c8;
	font-weight: 400;
	font-style: italic;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.edit-question textarea::placeholder {
	color: white;
}

.edit-question {
	display: flex;
	align-items: center;
}

.edit-question textarea {
	display: block;
	width: 100%;
	padding: 0.25em;
	background-size: 1.25em;
	font-size: 1em;
	border-radius: 5px;
	border: 1px solid #c9c9d6;
	color: #000;
}

.edit-question textarea:focus-visible {
	outline: none;
	border: 1.25px solid #00a6d9;
	box-shadow: #00a6d9 1px 2px 5px -1px;
}

.edit-question textarea::placeholder {
	color: #c4c4c8;
	font-weight: 400;
	font-style: italic;
}

.filter-button {
	display: block;
	width: 100%;
	padding: 0.5em 2em 0.5em 0.5em;
	background: white url("../../../assets/filterArrowDown.svg") no-repeat right 1em center;
	background-size: 0.75em;
	font-size: 1em;
	border: none;
	border-radius: 5px;
	color: #000;
	text-align: center;
	border: 1px solid #c9c9d6;
}

.filter-title {
	text-align: center;
	padding: 0.5em 0em;
	color: #00a6d9;
	font-weight: 500;
	font-size: 1.25em;
	border-bottom: 1px solid #f8f8f9;
}

.filter-content {
	background: white;
	position: absolute;
	right: 1%;
	top: 7.5%;
	border-radius: 10px;
	z-index: 1;
}

.checked-option {
	background: transparent url("../../../assets/CheckMark.svg") no-repeat center;
	border-color: #15da28 !important;
}

.filter-options {
	display: flex;
	flex-direction: column;
	gap: 0.25em;
}

.filter-option {
	border: 1px solid #b3b3c9;
	border-radius: 0.3em;
}

.filter-text {
	color: #b3b3c9;
}

.checked-text {
	color: #000;
}

.reset-container {
	width: 100%;
	margin: auto;
	padding: 0.5em;
}

.reset-button {
	color: white;
	background: #00a6d9;
	width: 100%;
	padding: 0.5em;
	border-radius: 5px;
}

.filter-button span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	align-items: center;
}
.seperateFilter {
	display: grid;
	grid-template-columns: auto 1px auto;
	align-items: center;
	height: 100%;
	padding: 2%;
}

.item {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 5px;
}

.separator {
	height: 100%;
}

.item.active {
	position: relative;
	background-color: #a1dff31a;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.item.active::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	background-color: #00a6d9;
}

.iconFilter {
	margin-right: 8px;
}
