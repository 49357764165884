@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Nunito Sans";
	src: url("/public/fonts/NunitoSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Nunito Sans";
	src: url("/public/fonts/NunitoSans-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Nunito Sans";
	src: url("/public/fonts/NunitoSans-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Nunito Sans";
	src: url("/public/fonts/NunitoSans-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@layer components {
	.advanced-settings-card {
		@apply flex h-24 max-w-[12rem] flex-col items-center gap-1 rounded-lg bg-white p-2 transition-all hover:-translate-y-2;
	}
}
*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
/* scrollbar design */
::-webkit-scrollbar {
	width: 0.5em;
}
::-webkit-scrollbar-track {
	background: #d9d9d980;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb {
	background: #393e42;
	border: 1px solid white;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background: #393e42;
	width: 0.5em;
}

body {
	scrollbar-color: #393e42 #d9d9d980;
	scrollbar-width: 0.5em;
	scroll-behavior: smooth;
	min-height: 100vh;
}
html {
	min-height: 100%;
}

/* this css is for the webpack-dev-server overlay. It will hide the red overlay react usually shows when there is an error in dev mode.
 We are using this because the virtualization npm package (react-virtuoso) is causing an unharmful error that is annoing in developement */
iframe#webpack-dev-server-client-overlay {
	display: none;
}
