.container-preview{
    height: 100%;
}
.header-preview img {
    width: 10%;
}
.header-preview, .footer-preview{
    height: 15%;
}
.footer-preview{
    color: white;
    font-weight: 600;
    font-size: 1.5em;
    letter-spacing: 2px;
    padding: 0.5em;
    display: flex;
    align-items: center;
    width: 100%;
}
.content-preview {
    height: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tv-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.antennas {
    position: absolute;
    top: -60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.antenna {
    width: 6px;
    height: 60px;
    background-color: white;
    transform-origin: bottom center;
}

.antenna.left {
    transform: rotate(-40deg);
    position: relative;
    left: 50%;
    top: 0.5em;
}

.antenna.right {
    transform: rotate(40deg);
    position: relative;
    top: 0.5em;
    right: 50%;
}

.octagon-wrapper {
    background-color: white;
    clip-path: polygon(
        30% 0%, 70% 0%, 100% 30%, 100% 70%,
        70% 100%, 30% 100%, 0% 70%, 0% 30%
    );
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.5em;
}

.octagon {
    width: 200px;
    height: 200px;
    clip-path: polygon(
        30% 0%, 70% 0%, 100% 30%, 100% 70%,
        70% 100%, 30% 100%, 0% 70%, 0% 30%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.5em;

}

.pause {
    display: flex;
    gap: 10px;
}

.bar {
    width: 20px;
    height: 60px;
    background-color: white;
}

.text {
    display: flex;
    flex-direction: column;
    position: relative;
    left: -5%;
    border: 6px solid white;
}

.live, .window {
    color: white;
    font-size: 2.75em;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding-left: 1.6em;
    padding-right: 20px;
}

.live{
    font-style: italic;
}