.media-wrapper {
	display: grid;
	padding: 10px;
	grid-template-columns: repeat(auto-fit, minmax(150px, 175px));
	gap: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}
@media (max-width: 450px) {
	.media-wrapper {
		align-items: center;
		justify-content: center;
	}
}
